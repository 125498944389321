<template>
  <div class="input-form flex flex-col">
    <label v-if="hasLabel">{{ label }}</label>
    <input
      ref="inputRef"
      :id="`input-${id}`"
      :type="type"
      :placeholder="placeholder"
      :readonly="readonly"
      :value="value"
      :min="min"
      :max="max"
      @input="onChange" />
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    value: String || Number,
    hasLabel: {
      type: Boolean,
      default: true,
    },
    label: String,
    placeholder: String,
    type: {
      type: String,
      default: 'text',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    min: Number,
    max: Number,
  },
  data () {
    return {
      id: null
    }
  }, 
  mounted () {
    this.id = this._uid
  },
  methods: {
    onChange: function(e) {
      this.$emit('input', e.target.value);
    },
  }
}
</script>

<style lang="scss" scoped>
.input-form {
  label {
    color: #555555;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
  }

  input {
    color: #333333;
    height: 40px;
    border-bottom: 1px solid #d7d7d7;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.14px;
    padding-left: 9px;
    padding-right: 9px;

    &::placeholder {
      color: #c1c1c1;
    }
  }
}
</style>