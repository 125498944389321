<template>
  <div class="input-form flex flex-col">
    <label v-if="hasLabel">{{ label }}</label>
    <textarea
      ref="textareaRef"
      :placeholder="placeholder"
      :readonly="readonly"
      :value="value"
      @input="onChange" />
  </div>
</template>

<script>
export default {
  name: 'Textarea',
  props: {
    value: String,
    hasLabel: {
      type: Boolean,
      default: true,
    },
    label: String,
    placeholder: String,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange: function(e) {
      this.$emit('input', e.target.value);
    },
  }
}
</script>

<style lang="scss" scoped>
.input-form {
  label {
    color: #555555;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
  }

  textarea {
    color: #333333;
    height: 40px;
    border-bottom: 1px solid #d7d7d7;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.14px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;

    resize: none;
    height: 276px;
    border: solid 1px #ddd;
    background-color: #f4f4f4;

    &::placeholder {
      color: #c1c1c1;
    }
  }
}
</style>